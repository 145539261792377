@font-face {
	font-family: "SoraRegular";
	src: local("SoraRegular"), url("./fonts/Sora-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "SoraMedium";
	src: local("SoraMedium"), url("./fonts/Sora-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "SoraBold";
	src: local("SoraBold"), url("./fonts/Sora-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "SoraLight";
	src: local("SoraLight"), url("./fonts/Sora-Light.ttf") format("truetype");
}

@font-face {
	font-family: "InterRegular";
	src: local("InterRegular"),
		url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "InterMedium";
	src: local("InterMedium"), url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "InterBold";
	src: local("InterBold"), url("./fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "InterLight";
	src: local("InterLight"), url("./fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
	font-family: "RubikRegular";
	src: local("RubikRegular"),
		url("./fonts/Rubik-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "RubikMedium";
	src: local("RubikMedium"), url("./fonts/Rubik-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "RubikBold";
	src: local("RubikBold"), url("./fonts/Rubik-Bold.ttf") format("truetype");
}

* {
	position: relative;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: InterRegular;
	font-size: 1rem;
	color: #050911;
	background-color: #fff;
}

a {
	text-decoration: none;
}

a:not(.decorated) {
	color: #050911;
}

textarea {
	resize: none;
}

.test {
	border: 1px solid red;
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left;
	justify-content: left;
}

.flex-row-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

.full-height {
	height: 100% !important;
}

.full {
	width: 100% !important;
}

.half {
	width: 50% !important;
}

.fit {
	width: fit-content !important;
}

.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.block {
	display: block !important;
}

.border {
	border: 1px solid rgba(229, 231, 235, 0.5);
}

.border-top {
	border-top: 1px solid rgba(229, 231, 235, 0.5);
}

.border-left {
	border-left: 1px solid rgba(229, 231, 235, 0.5);
}

.border-bottom {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.border-right {
	border-right: 1px solid rgba(229, 231, 235, 0.5);
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.full-page {
	min-height: 100vh !important;
}

.text-regular-sora {
	font-family: SoraRegular;
}

.text-medium-sora {
	font-family: SoraMedium;
}

.text-bold-sora {
	font-family: SoraBold;
}

.text-light-sora {
	font-family: SoraLight;
}

.text-regular-inter {
	font-family: InterRegular;
	/* font-weight: normal; */
}

.text-medium-inter {
	font-family: InterMedium;
	/* font-weight: 600; */
}

.text-bold-inter {
	font-family: InterBold !important;
	/* font-weight: 700; */
}

.text-regular-rubik {
	font-family: RubikRegular;
	/* font-weight: normal; */
}

.text-medium-rubik {
	font-family: RubikMedium;
	/* font-weight: 600; */
}

.text-bold-rubik {
	font-family: RubikBold !important;
	/* font-weight: 700; */
}

.text-light-inter {
	font-family: InterLight;
}

.text-white {
	color: #ffffff !important;
}

.text-default {
	color: #050911 !important;
}

.text-orange {
	color: #ff692a !important;
}

.text-orange-light {
	color: #ff9a6b !important;
}

.text-orange-dark {
	color: #d44513 !important;
}

.text-red {
	color: #ff3819 !important;
}

.title-lg {
	font-size: 2.5rem;
}

.title-xl {
	font-size: 3rem;
}

.title-md {
	font-size: 2rem;
}

.text-xl {
	font-size: 1.5rem !important;
}

.text-lg {
	font-size: 1.125rem !important;
}

.text-md {
	font-size: 1rem !important;
}

.text-sm {
	font-size: 0.875rem !important;
}

.label-md {
	font-size: 0.75rem !important;
}

.label-sm {
	font-size: 0.563rem !important;
}

.opaque {
	opacity: 1 !important;
}

.opacity-3 {
	opacity: 0.3 !important;
}

.opacity-5 {
	opacity: 0.5 !important;
}

.opacity-7 {
	opacity: 0.7 !important;
}

.upper {
	text-transform: uppercase;
}

.btn-default {
	background-color: #ff692a !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}

.btn-default:hover {
	background-color: rgb(255, 105, 42, 0.9) !important;
}

.btn-default:active {
	background-color: #fc6423 !important;
}

.bg-cover {
	background-size: cover !important;
	background-position: center !important;
}

.bg-default {
	background-color: #ff692a !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.bg-cream {
	background-color: #f8f5f1;
}

.bg-black {
	background-color: #050911;
}

.rounded {
	border-radius: 8px !important;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	height: 90px;
	width: 100%;
	border-bottom: 1px solid #ddd;
	z-index: 4;
}

.main-nav .nav-link {
	margin-left: 30px;
}

.nav-btn {
	padding: 8px 18px 8px 18px;
}

.nav-sm {
	display: none;
}

.nav-toggle {
	position: absolute;
	right: 0;
	height: 90px;
	width: 28px;
	cursor: pointer;
}

.fries {
	width: 100%;
}

.fries > div {
	transition: 0.3s;
}

.mid-fry {
	width: 28px;
	height: 2px;
	float: right;
	background-color: #050911;
}

.top-fry {
	position: absolute;
	width: 28px;
	height: 2px;
	top: -9px;
	right: 0;
	opacity: 1 !important;
	background-color: #050911;
}

.bottom-fry {
	position: absolute;
	width: 28px;
	height: 2px;
	bottom: -9px;
	right: 0;
	background-color: #050911;
}

.fries.active .mid-fry {
	opacity: 0;
}

.fries.active .top-fry {
	transform: rotate(40deg);
	top: 0;
}

.fries.active .bottom-fry {
	transform: rotate(-40deg);
	bottom: 0;
}

.nav-panel {
	position: fixed;
	bottom: 100vh !important;
	right: 0;
	height: calc(100vh - 70px);
	width: 100%;
	z-index: 3;
	transition: 0.3s ease-in;
	padding: 30px 0;
}

.nav-panel .nav-link {
	margin-bottom: 20px;
}

.nav-panel-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	padding-bottom: 23px;
}

.nav-panel-btn {
	height: 55px;
	width: 100%;
}

.content {
	padding-top: 90px;
	overflow-x: hidden !important;
}

.hero {
	height: fit-content;
	overflow: hidden;
}

.hero-col {
	height: 500px;
}

.hero-bg {
	position: absolute;
	height: 480px;
	bottom: 0;
	left: 0;
}

.hero-desc {
	margin-top: 15px;
	margin-bottom: 30px;
}

.hero-form {
	height: 65px;
}

.hero-input {
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	padding-right: 205px;
}

.hero-form-btn {
	position: absolute;
	right: 5px;
	height: 55px;
	width: 185px;
}

.blog-highlight {
	margin-bottom: 50px;
}

.blog-highlight-img {
	width: 100%;
	height: 400px;
	margin-bottom: 30px;
}

.blog-highlight-header {
	margin-bottom: 10px;
}

.blog-items-title {
	margin-bottom: 30px;
}

.blog-item-header {
	margin-bottom: 15px;
}

.blog-item-tag {
	background: #f8f5f1;
	padding: 8px 12px;
	margin-right: 15px;
}

.blog-highlight-title {
	margin-bottom: 10px;
}

.blog-item-title {
	margin-bottom: 15px;
}

.blog-desc-cover {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 2;
	background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.7));
}

.blog-item-desc,
.update-item-desc {
	margin-bottom: 8px;
}

.blog-item-desc > p {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 3;
	-webkit-line-clamp: 3;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.update-item-desc > p {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.blog-item:not(:last-of-type) {
	border-bottom: 1px solid #e6e6e7;
}

.blog-item:not(:first-of-type) {
	padding: 30px 0;
}

.blog-item:first-of-type {
	padding-bottom: 30px;
}

.blog-item-content {
	width: calc(100% - 230px);
}

.blog-item-img {
	position: absolute;
	right: 0;
	width: 200px;
	height: 150px;
}

.preview-col {
	padding-left: 60px;
}

.updates-preview {
	margin-bottom: 30px;
}

.updates-preview-header {
	margin-bottom: 15px;
}

.update-item-title {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 10px;
}

.update-item {
	padding: 15px 0;
	border-bottom: 1px solid #ddd;
}

.about-preview {
	background-color: #f79222;
	padding: 30px;
	margin-bottom: 30px;
}

.about-preview-title,
.waitlist-preview-title {
	padding-right: 60px;
	margin-bottom: 15px;
}

.about-preview-desc,
.waitlist-preview-desc {
	margin-bottom: 30px;
}

.about-preview-btn,
.waitlist-preview-btn {
	height: 55px;
}

.about-preview-lightbulb {
	position: absolute;
	top: 15px;
	right: 15px;
}

.waitlist-preview {
	background-color: #050911;
	padding: 30px;
	margin-bottom: 30px;
}

.waitlist-preview-input {
	height: 65px;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	margin-bottom: 15px;
}

.page-footer {
	padding-top: 20px;
	padding-bottom: 20px;
}

.preview-footer-copy,
.preview-footer-socials {
	margin-bottom: 15px;
}

.preview-footer-links > a,
.page-footer-links > a {
	margin: 0 7px;
}

.preview-footer-socials > a,
.page-footer-socials > a {
	margin: 0 10px;
}

.updates-hero {
	height: 250px;
}

.updates-title {
	margin-bottom: 15px;
}

.update-item-tag.New {
	background-color: #38c976;
	color: #fff;
}

.update-item-tag.Feature {
	background-color: #2ab0fc;
	color: #fff;
}

.update-item-tag.Changed {
	background-color: #fcc132;
	color: #fff;
}

.update-item-tag.Improvement {
	background-color: #62d398;
	color: #fff;
}

.update-item-tag.Removed {
	background-color: #ff3819;
	color: #fff;
}

.update-list {
	padding-left: 40px;
}

.update-list-bar {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 5px;
	background-color: #e6e6e7;
}

.update-list-bar::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	background-color: #ff692a;
	width: 100%;
	height: 30%;
	max-height: 170px;
}

.about-hero {
	height: 470px;
}

.about-hero-content {
	background-color: #f79222;
	max-width: 1100px;
	padding: 50px 100px;
}

.hero-lightbulb {
	position: absolute;
	top: -60px;
	left: -60px;
}

.hero-robot {
	position: absolute;
	bottom: -20px;
	right: -20px;
	z-index: 2;
}

.about-content {
	padding-top: 90px;
}

.about-why {
	margin-bottom: 90px;
}

.about-title {
	margin-bottom: 90px;
}

.about-why-title {
	margin-bottom: 20px;
	width: 80%;
}

.about-cta {
	padding: 50px;
	margin-bottom: 60px;
}

.about-cta-title {
	margin-bottom: 15px;
}

.about-cta-desc {
	margin-bottom: 30px;
}

.about-cta-graphic {
	position: absolute;
	top: -105px;
}

.contact-form input {
	margin-bottom: 20px;
	height: 55px;
}

.contact-form textarea {
	height: 165px;
	margin-bottom: 20px;
}

.contact-title {
	margin-bottom: 20px;
}

.contact-desc {
	margin-bottom: 20px;
}

.contact-btn {
	height: 55px;
}

.waitlist-success-title {
	margin-top: 65px;
	margin-bottom: 20px;
}

.legal-container {
	padding: 50px 0;
}

.legal-title,
.legal-header {
	margin-bottom: 20px;
}

.legal-desc,
.legal-content {
	display: block;
	margin-bottom: 40px;
}

.not-found-title {
	margin-bottom: 20px;
}

.button-wrapper {
	display: none !important;
}

.post-back-container a {
	width: fit-content;
}

.post-back-container .back-arrow {
	font-size: 1.5rem;
	margin-right: 12px;
}

.post-back-container {
	margin-bottom: 15px;
}

.blog-content {
	margin-bottom: 50px;
}

.recommended-title {
	margin-bottom: 30px;
}

.d-hidden {
	display: none;
}

@media screen and (min-width: 1600px) {
}

@media screen and (max-width: 992px) {
	.nav-logo {
		height: 30px;
	}

	.nav-panel.active {
		height: calc(100vh - 90px);
		right: 0;
		width: 100%;
		border-radius: 0;
		bottom: 0 !important;
	}

	.nav-lg {
		display: none;
	}

	.nav-sm {
		display: flex;
	}

	.title-lg,
	.title-xl {
		font-size: 2rem !important;
	}

	.title-md {
		font-size: 1.5rem;
	}

	.text-lg {
		font-size: 1rem !important;
	}

	.hero-content {
		text-align: center !important;
		z-index: 2;
		padding-top: 50px;
	}

	.hero-desc {
		margin-bottom: 30px !important;
	}

	.hero-form {
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		height: fit-content !important;
		background-color: transparent !important;
		border: none !important;
	}

	.hero-input {
		height: 65px;
		margin-bottom: 15px;
		border: 1px solid #ddd !important;
		border-radius: 8px !important;
	}

	.hero-form-btn {
		position: relative;
		right: auto;
		height: 55px !important;
		width: 100%;
	}

	.hero-bg-container {
		height: 350px;
	}

	.hero-bg {
		position: absolute !important;
		width: 170%;
		left: -30%;
		z-index: 1;
	}

	.blog-highlight-img {
		width: 100%;
		height: 160px;
	}

	.blog-item-desc,
	.blog-item-link {
		display: none;
	}

	.blog-item-img {
		position: absolute;
		right: 0;
		width: 150px;
		height: 100px;
	}

	.blog-item-content {
		width: calc(100% - 165px);
	}

	.m-hidden {
		display: none;
	}

	.d-hidden {
		display: inherit;
	}

	.preview-col {
		padding: 50px 10px 0 10px;
	}

	.page-footer {
		display: flex;
	}

	.page-footer-copy {
		text-align: center;
	}

	.page-footer-copy,
	.page-footer-socials {
		margin-bottom: 15px;
	}

	.page-footer-links {
		-webkit-justify-content: center;
		justify-content: center;
		margin-bottom: 15px;
	}

	.post-container {
		padding-bottom: 0 !important;
	}

	.updates-hero {
		text-align: center;
	}

	.rocket-man {
		width: 95%;
	}

	.about-hero-content {
		width: calc(100% - 20px);
		padding: 40px;
	}

	.about-hero-content .title-md {
		line-height: 2rem;
		z-index: 2;
	}

	.about-why-title {
		width: 100%;
		font-size: 1.5rem !important;
	}

	.about-why-desc {
		margin-bottom: 20px !important;
	}

	.about-why-img {
		width: 90%;
		margin-bottom: 0;
	}

	.about-why {
		margin-bottom: 40px;
	}

	.about-cta {
		padding: 20px;
		margin-bottom: 60px;
		margin-top: 60px;
	}

	.about-content {
		padding-top: 50px !important;
		padding: 30px;
	}

	.about-title {
		margin-bottom: 40px;
		font-size: 2.5rem !important;
	}

	.hero-lightbulb {
		top: -60px;
		left: -60px;
		height: 120px;
	}

	.hero-robot {
		height: 120px;
		position: absolute;
		bottom: -20px;
		right: -30px;
		z-index: 2;
	}
}
